<template>
  <screen_width_cont>
    <div class="myBodyGeneral" @click.prevent="focusGralCotiDestiOn">
      <div class="myHeader" ref="myHeader_cotizDestin">
        <div class="conti_destin_myHeader">
          <div class="cont_300_basico ancho_acotado">
            <div class="header_top">
              <headerCerrarInfoCuenta
                @callCuenta="callCuentaLocal"
                @callInfo="callInfoLocal"
                @cancelar="cancelarLocal"
              />
            </div>
          </div>

          <div class="cotizDestinTitle title title_margin_top">
            {{ t("app.cotizadorDestinatario.tit") }}
          </div>

          <div class="cont_300_basico ancho_acotado">
            <div class="cotizDestinListaCont">
              <div class="cotizDestinListaTitle text_basico f_w_500">
                {{ t("app.cotizadorDestinatario.sub_tit_1") }}&nbsp;{{
                  t(`variables.pais.${paisDestino}`)
                }}
              </div>
              <div class="cotizDestinListaSubTitle">
                <span
                  >{{ t("app.cotizadorDestinatario.sub_tit_2") }}&nbsp;</span
                >
                <span class="cotizDestinListaSubTitleMoneda">
                  <!-- {{t("app.cotizadorDestinatario.sub_tit_3")}} -->
                  {{ t(`variables.monedas.${moneda_entrega}.plural`) }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="blur_sup" :style="blur_sup_cotizDestin"></div> -->
      <blurComp
        class="blur_sup"
        :style="blur_sup_cotizDestin"
        :scroll_to_="scroll_to_top"
        tipo="sup"
      />

      <div
        class="contenido centrado"
        :style="contenido_cotizDestin"
        v-show="cont_visible"
        @scroll="onScroll"
        ref="cotizDestinListaList"
      >
        <div class="cont_300_basico ancho_acotado cardCotiDest_alto_max">
          <div class="cotizDestinListaList">
            <div v-if="destinatariosLista.length > 0">
              <div v-for="(destinatario, i) in destinatariosLista" :key="i">
                <cardCotizadorDestinatario
                  @click.stop=""
                  :focusGralCotiDesti="focusGralCotiDesti"
                  :destinatario="destinatario"
                  :desplegarDetalle="desplegarDetalle"
                  :index="i"
                  :destinatarioSelected="destinatarioSelected"
                  @changeDestinatarioSelected="changeDestinatarioSelectedLocal"
                  @formaEntregaSelected="formaEntregaSelectedLocal"
                  @changeDesplegarFormaEntrega="
                    changeDesplegarFormaEntregaLocal
                  "
                  :formaPagoSelected="formaPagoSelectedLocal"
                  @nueva_forma_entrega="nueva_forma_entrega_local"
                  :last_item="i == destinatariosLista.length - 1"
                />
              </div>
            </div>

            <div v-else class="cotizDestinListaListSinDest">
              <div class="cotizDestinListaListSinDestImg">
                <img :src="sin_destinatarios" alt="" srcset="" />
              </div>
              <div class="cotizDestinListaListSinDestTit">
                {{ t("app.cotizadorDestinatario.agrega_destinatario") }}
              </div>
              <div class="cotizDestinListaListSinDestText">
                {{ t("app.cotizadorDestinatario.facil_agrega_dest") }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <blurComp
        class="blur_inf"
        :style="blur_inf_cotizDestin"
        :scroll_to_="scroll_to_bottom"
        tipo="inf"
      />

      <div class="footer_estrecho_centrado" ref="footer_cotizDestin">
        <div class="footer_estrecho">
          <div class="cotizadorDestinHr">
            <div class="cotizadorDestinError" v-show="errorDestinatario.status">
              {{
                errorDestinatario.msg == ""
                  ? "*Campo obligatorio"
                  : errorDestinatario.msg
              }}
            </div>
            <hr />
          </div>

          <div class="cotizDestinNuevoDest">
            <div class="cotizDestinNuevoDestText" @click="callNewDestinatario">
              {{ t("app.cotizadorDestinatario.NewDestinatario") }}
            </div>
            <div class="cotizDestinNuevoDestBtn" @click="callNewDestinatario">
              <img :src="iconCotiNuevoDestinatario" alt="" srcset="" />
            </div>
          </div>

          <div class="cotizDestinBtn">
            <btnAction
              :textBtn="t('app.comunes.btns.continuar')"
              @action="registrarDestinatario"
              colorBack="azul"
              colorText="blco"
              :estadoBtn="estadoBtn"
              :msg_completar="t('app.comunes.btns.select_destinatario')"
            />
          </div>

          <div class="cotizDestinEtapas">
            <pasosGiro paso="2" totales="6" />
          </div>

          <div class="cotizDestinVolverCont">
            <volver @volver_emit="volver" />
          </div>

          <div class="footer_env_seguro">
            <enviosSeguros @callEnvioSeguroModal_="callEnvioSeguroModal" />
          </div>
        </div>
      </div>
    </div>
  </screen_width_cont>
</template>

<script>
import { modalController, menuController, alertController } from "@ionic/vue";
import { useI18n } from "vue-i18n";
import headerCerrarInfoCuenta from "../components/generales/headerCerrarInfoCuenta.vue";
import btnAction from "../components/generales/btnAction.vue";
import pasosGiro from "../components/generales/pasosProceso.vue";
import cardCotizadorDestinatario from "../components/simulador/cardCotizadorDestinatario.vue";
import { mapActions, mapMutations, mapState } from "vuex";
import envioSeguroHome from "./modales/enviosSegurosHome.vue";
import enviosSeguros from "../components/generales/envios_seguros.vue";
import volver from "../components/generales/volver.vue";
import blurComp from "../components/generales/blur.vue";
import {
  blur_sup_scroll,
  blur_inf_scroll,
  contenido_style,
  blur_sup_style,
  blur_inf_style,
} from "../assets/funciones/blur";
import screen_width_cont from "../components/menuSp/screen_width_cont.vue";

export default {
  setup() {
    const { t } = useI18n();
    return { t };
  },
  name: "cotizadorDestinatario",
  components: {
    headerCerrarInfoCuenta,
    btnAction,
    pasosGiro,
    cardCotizadorDestinatario,
    enviosSeguros,
    volver,
    blurComp,
    screen_width_cont,
  },
  data() {
    return {
      destinatarioSelected: -1,
      focusGralCotiDesti: true,
      desplegarDetalle: -1,
      indexDestinatarioSeleccionado: -1,
      destinatarioElegido: {},
      iconCotiNuevoDestinatario: require("../assets/imgs/iconCotiNuevoDestinatario.svg"),
      errorDestinatario: { status: false, msg: "" },
      formaPagoSelectedLocal: "-1",
      recienMontado: true,
      sin_destinatarios: require("../assets/imgs/cotizador/sin_destinatarios.svg"),
      header: 0,
      footer: 0,
      cont_visible: false,
      scroll_to_bottom: true,
      scroll_to_top: true,
      contenido_cotizDestin: {},
      blur_sup_cotizDestin: {},
      blur_inf_cotizDestin: {},
      enVista: true,
    };
  },
  computed: {
    ...mapState([
      "transaccion",
      "remitente",
      "configuracion",
      "respSelectDestinatario",
      "heigthScreen",
      "widthScreen",
      "plataforma",
      "nav_stack",
      "monto_inicio",
      "pais_destino_inicial",
    ]),

    destinatariosLista() {
      let aux = [];
      if (
        this.remitente &&
        this.remitente.destinatarios &&
        this.transaccion &&
        this.transaccion.pais_destino
      ) {
        let dstinatariosIdArray = Object.keys(this.remitente.destinatarios);
        dstinatariosIdArray.forEach((destinatario) => {
          if (
            destinatario != "0" &&
            this.remitente.destinatarios[
              destinatario
            ].pais_moneda_destino_id.split("_")[0] ==
              this.transaccion.pais_destino.split("_")[0]
          ) {
            aux.push(this.remitente.destinatarios[destinatario]);
          }
        });
      }
      if (aux.length > 0) {
        aux.sort((a, b) => {
          return Intl.Collator().compare(a.nombre1, b.nombre1);
        });
      }
      return aux;
    },
    paisDestino() {
      let aux = "";
      if (
        this.configuracion &&
        this.configuracion.pais &&
        this.transaccion &&
        this.transaccion.pais_destino
      ) {
        aux = this.transaccion.pais_destino.split("_")[0];
      }
      return aux;
    },
    moneda_entrega() {
      let aux = "";
      if (this.transaccion && this.transaccion.pais_destino) {
        aux = this.transaccion.pais_destino.split("_")[1];
      }
      return aux;
    },
    paisVigente() {
      let aux = true;
      if (this.configuracion.pais[this.transaccion.pais_destino].pausa == "1") {
        aux = false;
      }
      return aux;
    },
    // contenido_cotizDestin() {
    //   let vh = this.heigthScreen;
    //   let clase = {
    //     top: `${this.header}px`,
    //     height: `${vh - (this.header + this.footer)}px`,
    //     ["padding-bottom"]: 1 + "rem",
    //   };
    //   console.log(vh, this.header, this.footer);
    //   return clase;
    // },
    // blur_sup_cotizDestin() {
    //   return { top: `${this.header}px` };
    // },
    // blur_inf_cotizDestin() {
    //   return { bottom: `${this.footer}px` };
    // },
    estadoBtn() {
      let aux = "inactive";
      if (Object.keys(this.destinatarioElegido).length > 0) {
        aux = "active";
      }
      return aux;
    },
  },
  methods: {
    ...mapMutations([
      "updateBloquearNavegacion",
      "updateMenuSpDesde",
      "updateDataDestinSelected",
      "updateProcesoGeneral",
      "setshowSpinner",
      "set_nav_stack",
      "reset_transaccion",
      "set_pre_values_cotizador",
    ]),
    ...mapActions(["validarTarjetaBeneficiario"]),

    // prueba(i){
    //   console.log(i);
    //   // let elemento = this.$refs[`card_coti_fe${i}`];
    //   console.log(i);
    // },
    callCuentaLocal() {
      menuController.open("firstMenu");
    },
    callInfoLocal() {
      this.updateMenuSpDesde({ desde: "cotizadorDestinatario", menuSp: true });

      this.set_nav_stack({
        desde: ["cotizadorDestinatario"],
        menuSp: this.nav_stack.menuSp,
      });

      this.$router.push({
        name: "centroAyuda",
        params: { tema: "0" },
        replace: true,
      });
    },
    cancelarLocal() {
      this.reset_transaccion();
      this.set_pre_values_cotizador({
        monto_inicio: this.monto_inicio,
        pais_destino_inicial: this.pais_destino_inicial,
      });
      this.$router.push({ name: "home", replace: true });
    },
    volver() {
      this.$router.push({ name: "cotizador", replace: true });
    },
    changeDestinatarioSelectedLocal(data) {
      // console.log(data);
      if (!this.recienMontado) {
        this.errorDestinatario.status = false;
        this.errorDestinatario.msg = "";
        if (
          data.estado &&
          this.destinatariosLista[data.index] &&
          this.destinatariosLista[data.index].detalle_entrega
        ) {
          this.destinatarioSelected = data.index;

          let detalle_selected_array = Object.entries(
            this.destinatariosLista[data.index].detalle_entrega
          );
          // console.log(data, this.destinatarioSelected, this.destinatariosLista , detalle_selected_array);
          if (detalle_selected_array.length > 0) {
            this.formaPagoSelectedLocal = "-1";
            this.desplegarDetalle = data.index;
            this.destinatarioElegido = {};
          } else {
            this.destinatarioElegido = {
              destinatarioIndex: data.index,
              formaEntregaIndex: detalle_selected_array[0][0],
            };
          }
        } else {
          if (
            this.destinatariosLista[data.index].detalle_entrega &&
            Object.entries(this.destinatariosLista[data.index].detalle_entrega)
              .length > 0
          ) {
            this.desplegarDetalle = -1;
            this.formaPagoSelectedLocal = "-1";
            this.destinatarioSelected = -6;
          } else {
            if (this.destinatarioSelected == data.index) {
              this.destinatarioSelected = -1;
              this.formaPagoSelectedLocal = "-1";
            }
          }
          this.destinatarioElegido = {};
        }
      }
    },
    formaEntregaSelectedLocal(data) {
      // console.log(data);
      if (this.formaPagoSelectedLocal == data.formaEntregaIndex) {
        //retirar forma de pago
        this.formaPagoSelectedLocal = "-1";
        this.destinatarioElegido = {};
      } else {
        this.formaPagoSelectedLocal = data.formaEntregaIndex;
        this.destinatarioElegido = data;
      }
    },
    // focusGralCotiDestiChangeLocal(estado) {
    //   this.focusGralCotiDesti = estado;
    // },
    focusGralCotiDestiOn() {
      this.focusGralCotiDesti = true;
      this.desplegarDetalle = -new Date().getTime();

      if (Object.keys(this.destinatarioElegido).length == 0) {
        this.desplegarDetalle = -new Date().getTime();
        if (
          this.destinatarioSelected > -1 &&
          this.destinatariosLista[this.destinatarioSelected] &&
          this.destinatariosLista[this.destinatarioSelected].detalle_entrega
            .length > 0
        ) {
          this.destinatarioSelected = -4;
          this.destinatarioElegido = {};
        }
      }
    },
    async callEnvioSeguroModal() {
      this.updateBloquearNavegacion(true);
      const modal = await modalController.create({
        component: envioSeguroHome,
        // cssClass: 'my-custom-class',
        // componentProps: {
        //   dataTransfer: data
        // },
      });
      modal.onDidDismiss().then(() => {
        this.updateBloquearNavegacion(false);
      });
      return modal.present();
    },
    registrarDestinatario() {
      this.setshowSpinner(false);
      if (Object.keys(this.destinatarioElegido).length == 0) {
        this.errorDestinatario.status = true;
        this.errorDestinatario.msg = "";
        this.callMensajeError();

        return;
      }
      //  console.log(this.destinatariosLista[this.destinatarioElegido.destinatarioIndex]
      //         .detalle_entrega[this.destinatarioElegido.formaEntregaIndex]);
      let destinatario = {
        accion: "seleccionar_transaccion",
        id_persona:
          this.destinatariosLista[this.destinatarioElegido.destinatarioIndex]
            .id,
        forma_entrega_id:
          this.destinatariosLista[this.destinatarioElegido.destinatarioIndex]
            .detalle_entrega[this.destinatarioElegido.formaEntregaIndex]
            .forma_entrega_id,
        tipo_cuenta:
          this.destinatariosLista[this.destinatarioElegido.destinatarioIndex]
            .detalle_entrega[this.destinatarioElegido.formaEntregaIndex]
            .tipo_cuenta,
        id_banco_destino:
          this.destinatariosLista[this.destinatarioElegido.destinatarioIndex]
            .detalle_entrega[this.destinatarioElegido.formaEntregaIndex]
            .id_banco_destino,
        cuenta_corriente:
          this.destinatariosLista[this.destinatarioElegido.destinatarioIndex]
            .detalle_entrega[this.destinatarioElegido.formaEntregaIndex]
            .numero_cuenta,
        nro_cuenta:
          this.destinatariosLista[this.destinatarioElegido.destinatarioIndex]
            .detalle_entrega[this.destinatarioElegido.formaEntregaIndex]
            .numero_cuenta,
        motivo_transaccion: "",
        detalle_entrega_id: this.destinatarioElegido.formaEntregaIndex,
      };

      if (this.paisVigente) {
        this.validarTarjetaBeneficiario(destinatario);
      }
    },
    changeDesplegarFormaEntregaLocal(ind) {
      this.errorDestinatario.status = false;
      this.errorDestinatario.msg = "";
      this.desplegarDetalle = ind;
      this.destinatarioSelected = ind;
    },
    async callNewDestinatario() {
      this.$router.push({ name: "nuevoDestinatarioDisclaimer" });
    },

    async callMensajeError() {
      let alert = await alertController.create({
        // header: this.title,
        subHeader:
          "Primero debes seleccionar un destinatario y medio de entrega",
        backdropDismiss: false,
        buttons: [
          {
            text: `Ok`,
            role: "cancel",
            cssClass: "customBtnClassAutentica",
          },
        ],
      });
      await alert.present();
    },

    nueva_forma_entrega_local(index) {
      this.updateProcesoGeneral("cotizador");
      this.updateDataDestinSelected({
        tipo: "nueva_forma_entrega",
        data: this.destinatariosLista[index],
        indexFE: 0,
      });
      this.$router.push({ name: "destinMyDataForm", replace: true });
    },
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      this.scroll_to_top = blur_sup_scroll(scrollTop);
      this.scroll_to_bottom = blur_inf_scroll(
        scrollTop,
        clientHeight,
        scrollHeight
      );
    },
  },
  watch: {
    respSelectDestinatario() {
      this.errorDestinatario.status = false;
      this.errorDestinatario.msg = "";
      if (this.respSelectDestinatario.status && this.enVista) {
        if (this.respSelectDestinatario.resp) {
          this.$router.push({ name: "cotizadorMotivoRemesa", replace: true });
        } else {
          this.errorDestinatario.status = true;
          this.errorDestinatario.msg = this.respSelectDestinatario.msg;
        }
        this.setshowSpinner(false);
      }
    },
    heigthScreen() {
      this.contenido_cotizDestin = contenido_style(
        this.heigthScreen,
        this.header,
        this.footer,
        this.plataforma == "web" ? 63 : 0
      );
    },
  },
  mounted() {},
  ionViewWillEnter() {
    this.enVista = true;
    this.updateMenuSpDesde({ desde: "cotizadorDestinatario", menuSp: false });

    setTimeout(() => {
      this.recienMontado = false;
    }, 200);
  },
  ionViewDidEnter() {
    // console.log(this.transaccion);
    // console.log(this.destinatariosLista);
    // console.log(this.transaccion.id_persona);
    if (this.transaccion.id_persona) {
      let aux1 = this.destinatariosLista.findIndex((destinatario) => {
        return destinatario.id == this.transaccion.id_persona;
      });

      if (aux1 > -1) {
        this.destinatarioSelected = aux1;
      }

      this.formaPagoSelectedLocal = this.transaccion.detalle_entrega_id;
      if (
        this.destinatarioSelected > -1 &&
        this.formaPagoSelectedLocal != "-1"
      ) {
        this.destinatarioElegido = {
          destinatarioIndex: this.destinatarioSelected,
          formaEntregaIndex: this.formaPagoSelectedLocal,
        };
        console.log("encontrado");
      } else {
        console.log("timing");
      }
    }

    this.$nextTick(() => {
      setTimeout(() => {
        this.header = this.$refs["myHeader_cotizDestin"].offsetHeight;
        this.footer = this.$refs["footer_cotizDestin"].offsetHeight;
        this.cont_visible = true;
        this.contenido_cotizDestin = contenido_style(
          this.heigthScreen,
          this.header,
          this.footer,
          this.plataforma == "web" ? 63 : 0
        );
        this.blur_sup_cotizDestin = blur_sup_style(this.header);
        this.blur_inf_cotizDestin = blur_inf_style(this.footer);
      }, 100);

      setTimeout(() => {
        if (
          this.$refs["cotizDestinListaList"].scrollHeight >
          this.$refs["cotizDestinListaList"].clientHeight
        ) {
          this.scroll_to_bottom = false;
        }
      }, 100);
    });
  },
  ionViewWillLeave() {
    this.enVista = false;
  },
};
</script>

<style>
/* .cardCotiDest_alto_max{
  
} */
.conti_destin_myHeader {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  max-width: 35rem;
  padding: 0 2rem;
}
.cotizDestinTitle {
  text-align: center;
  color: #801b1c;
}
.cotizDestinListaCont {
  margin-top: 0.8rem;
}
.cotizDestinListaTitle {
  letter-spacing: 1px;
  color: #614ad3;
}
.cotizDestinListaSubTitle {
  font-style: normal;
  font-weight: 400;
  font-size: 0.7rem;
  line-height: 0.8rem;
  letter-spacing: 0.5px;
  color: #614ad3;
  padding-bottom: 0.2rem;
}
.cotizDestinListaSubTitleMoneda {
  font-weight: 500;
  font-size: 0.8rem;
}
.cotizDestinListaList {
  overflow: visible;
  height: 100%;
}
.cotizDestinListaListSinDest {
  background: linear-gradient(180deg, #f3f3f3 0%, #ffffff 100%);
  border-radius: 33px;
  /* height: 15rem; */
  height: 100%;
}
.cotizDestinListaListSinDestImg {
  padding-top: 1.2rem;
  display: flex;
  justify-content: center;
}
.cotizDestinListaListSinDestImg img {
  width: 4rem;
}
.cotizDestinListaListSinDestTit {
  margin-top: 1rem;
  padding: 0 3rem;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 1px;
  color: #614ad3;
}
.cotizDestinListaListSinDestText {
  margin-top: 0.875rem;
  padding: 0 2rem 1rem 2rem;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 1px;
  color: #787878;
}

.cotizadorDestinHr {
  margin-left: 3.5625rem;
  margin-right: 3.5625rem;
  height: 0.25rem;
}
.cotizadorDestinError {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 500;
  font-size: 0.5rem;
  line-height: 0.5625rem;
  color: #801b1c;
  min-height: 0.5625rem;
  margin-bottom: 0;
  margin-top: auto;
}
.cotizadorDestinHr hr {
  border: 1px solid rgba(196, 196, 196, 0.3);
  margin-bottom: 0;
  margin-top: 0.1rem;
}

.cotizDestinNuevoDest {
  margin-top: 0.3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 3.5625rem;
  padding-right: 3.5625rem;
  cursor: pointer;
}
.cotizDestinNuevoDestText {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-size: 1rem;
  line-height: 1.2rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #614ad3;
}
.cotizDestinNuevoDestBtn {
  width: 3.4375rem;
  padding-left: 1rem;
}

.cotizDestinBtn {
  margin-top: 1.5rem;
  padding-left: 1.5625rem;
  padding-right: 1.5625rem;
  width: 100%;
}
.cotizDestinEtapas {
  margin-top: 1rem;
}
.cotizDestinVolverCont {
  margin-top: 0.6rem;
}
</style>