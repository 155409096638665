<template>
  <div class="cardCotiDestContainer">
    <div
      class="cardCotiDestItemIcon"
      @click.stop="toggleDetalleFormasEntrega = !toggleDetalleFormasEntrega"
    >
      {{ inicial }}
    </div>
    <div class="cardCotiDestItemInfo">
      <div
        class="cardCotiDestItemInfoNombre"
        @click.stop="toggleDetalleFormasEntrega = !toggleDetalleFormasEntrega"
      >
        {{ nombre }}
      </div>
      <div
        class="cardCotiDestItemSelectFormaEntrega"
        @click.stop="toggleDetalleFormasEntrega = !toggleDetalleFormasEntrega"
        v-if="opcionesFormaEntrega.length > 0"
      >
        <span
          class="conDeco"
          v-if="Object.keys(detalleFormaEntregaSeleccionado).length == 0"
          >*{{
            t("app.cardCotizadorDestinatario.selectFormaEntrega")
          }}&nbsp;<img :src="iconCotizadorSelect" alt="" srcset=""
        /></span>

        <div class="cardCotiDestItemInfoFormasEntregaRowTextSelected" v-else>
          <div class="">
            {{ detalleFormaEntregaSeleccionado.formaEntrega
            }}{{ detalleFormaEntregaSeleccionado.bancoNombre }}&nbsp;<img
              :src="iconCotizadorSelect"
              alt=""
              srcset=""
            />
          </div>
          <div
            class="cardCotiDestItemInfoFormasEntregaRowText_simple"
            v-if="detalleFormaEntregaSeleccionado.habil"
          >
            {{ detalleFormaEntregaSeleccionado.tipo_cuenta_nombre }}
          </div>
          <div
            class="cardCotiDestItemInfoFormasEntregaRowText_simple"
            v-if="detalleFormaEntregaSeleccionado.habil"
          >
            {{ detalleFormaEntregaSeleccionado.numeroCuentaMostrar }}
          </div>
        </div>
      </div>
      <!-- <div class="cardCotiDestItemSelectFormaEntregaSimple" v-else></div> -->
      <div class="cardCotiDestItemInfoDetalle">
        <div
          class="cardCotiDestItemInfoFormasEntrega"
          v-if="toggleDetalleFormasEntrega"
          :ref="`card_coti_fe${index}`"
          :class="last_item ? 'cardCotiDestContainer_ultimo' : ''"
        >
          <div
            v-for="(forma, i) in opcionesFormaEntrega"
            :key="i"
            @click.stop="forma.habil ? formaEntregaSelected(forma.id) : false"
          >
            <div
              class="cardCotiDestItemInfoFormasEntregaRow"
              v-if="forma.habil"
            >
              <div class="cardCotiDestItemInfoFormasEntregaRowCKB">
                <div v-if="forma.habil">
                  <img
                    :src="iconCheckedR1"
                    alt=""
                    srcset=""
                    v-if="forma.id == formaPagoSelected"
                  />
                  <img :src="iconUnCheckedR1" alt="" srcset="" v-else />
                </div>
              </div>
              <div
                :class="
                  forma.habil
                    ? 'cardCotiDestItemInfoFormasEntregaRowText'
                    : 'cardCotiDestItemInfoFormasEntregaRowTextNoHabil'
                "
              >
                <!-- <div class="" v-if="!forma.habil">
                  {{ forma.formaEntrega }}{{ forma.bancoNombre }}
                </div> -->
                <div class="cardCotiDestItemInfoFormasEntregaRowText_subrayado">
                  {{ forma.formaEntrega }}{{ forma.bancoNombre }}
                </div>
                <div
                  class="cardCotiDestItemInfoFormasEntregaRowText_simple"
                  v-if="forma.habil"
                >
                  {{ forma.tipo_cuenta_nombre }}
                </div>
                <div
                  class="cardCotiDestItemInfoFormasEntregaRowText_simple"
                  v-if="forma.habil"
                >
                  {{ forma.numeroCuentaMostrar }}
                </div>
              </div>
            </div>
          </div>

          <div class="cardCotiDestNuevoFE" @click="call_nueva_forma_entrega">
            <div class="cardCotiDestNuevoFEText">
              {{ t("app.cardCotizadorDestinatario.NewFE") }}
            </div>
            <div class="cardCotiDestNuevoFEtBtn" @click="callNewDestinatario">
              <img :src="iconCotiNuevoDestinatario" alt="" srcset="" />
            </div>
          </div>
        </div>
      </div>
      <div></div>
    </div>
    <div
      class="cardCotiDestItemSelect"
      @click.stop="selecDestToggleFormasEntrega"
    >
      <checkBoxRojo
        @changeStateChek="changeStateChekLocal"
        :destinatarioSelected="destinatarioSelected"
        :index="index"
        :marcarChecked="marcarChecked"
        :opcionesFormaEntrega="opcionesFormaEntrega"
        :formaPagoSelected="formaPagoSelected"
      />
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { mapMutations, mapState } from "vuex";
import checkBoxRojo from "./checkBoxRojoCotizador.vue";

export default {
  setup() {
    const { t } = useI18n();
    return { t };
  },
  props: {
    destinatario: Object,
    index: Number,
    destinatarioSelected: Number,
    desplegarDetalle: Number,
    formaPagoSelected: Number,
    last_item: Boolean,
  },
  components: {
    checkBoxRojo,
  },
  data() {
    return {
      selectedDest: false,
      toggleDetalleFormasEntrega: false,
      iconCotizadorSelect: require("../../assets/imgs/iconCotizadorSelect.svg"),
      marcarChecked: false,
      formaSeleccionadaIndex: "-1",
      iconCheckedR1: require("../../assets/imgs/cotizador/iconCheckedR1.svg"),
      iconUnCheckedR1: require("../../assets/imgs/cotizador/iconUnCheckedR1.svg"),
      iconCotiNuevoDestinatario: require("../../assets/imgs/iconCotiNuevoDestinatario.svg"),
    };
  },
  computed: {
    ...mapState(["configuracion", "transaccion"]),

    inicial() {
      let aux = "";
      if (this.destinatario) {
        aux = this.destinatario.nombre1.substring(0, 1);
      }
      return aux;
    },
    nombre() {
      let aux = "";
      if (this.destinatario) {
        aux = `${this.destinatario.nombre1} ${this.destinatario.apellido1} ${this.destinatario.apellido2}`;
      }
      return aux;
    },
    opcionesFormaEntrega() {
      let aux = [];
      if (this.destinatario && this.destinatario.detalle_entrega) {
        let aux2 = Object.entries(this.destinatario.detalle_entrega);
        aux2.forEach((element) => {
          if (element[0] != "0") {
            if (element[1].forma_entrega_id == "1") {
              if (
                element[1].moneda_destino_id ==
                this.transaccion.moneda_destino_id
              ) {
                aux.push(element[1]);
              }
            } else if (element[1].forma_entrega_id == "2") {
              if (
                element[1].moneda_caja == this.transaccion.moneda_destino_id
              ) {
                aux.push(element[1]);
              }
            } else if (element[1].forma_entrega_id == "6") {
              if (
                element[1].moneda_destino_id ==
                this.transaccion.moneda_destino_id
              ) {
                aux.push(element[1]);
              }
            }
          }
        });

        // aux.forEach((formaEntrega) => {
        //   if (formaEntrega.id_banco_destino != "0") {
        //     if (formaEntrega.forma_entrega_id == "1") {
        //       formaEntrega.bancoNombre = formaEntrega.bancos_nombre;
        //       formaEntrega.numeroCuentaMostrar = `N° ${formaEntrega.numero_cuenta}`;

        //       let aux4 = Object.entries(
        //         this.configuracion.pais[
        //           this.destinatario.pais_moneda_destino_id
        //         ].tipo_cuenta
        //       );

        //       aux4.forEach((tipo) => {
        //         if (tipo[1][0] == formaEntrega.tipo_cuenta) {
        //           formaEntrega.tipo_cuenta_nombre = tipo[1][1];
        //         }
        //       });
        //     } else {
        //       formaEntrega.bancoNombre = "";
        //       formaEntrega.numero_cuenta = "";
        //       formaEntrega.bancoNombre = ` ; ${formaEntrega.bancos_nombre}`;
        //     }
        //   } else {
        //     formaEntrega.bancoNombre = "aa";
        //     formaEntrega.numero_cuenta = "";
        //     let banco_caja_index = this.configuracion.pais[
        //       this.destinatario.pais_moneda_destino_id
        //     ].banco_caja.findIndex((bco_caja) => {
        //       bco_caja[0] == formaEntrega.banco_caja;
        //     });
        //     if (banco_caja_index > -1) {
        //       formaEntrega.bancoNombre =
        //         this.configuracion.pais[
        //           this.destinatario.pais_moneda_destino_id
        //         ].banco_caja[banco_caja_index][0];
        //     }
        //   }

        //   if (formaEntrega.forma_entrega_id == "2") {
        //     formaEntrega.formaEntrega = this.t(
        //       "app.cotizadorDestinatario.entregaCaja"
        //     );
        //     if (
        //       formaEntrega.moneda_caja ==
        //       this.transaccion.moneda_destino_id
        //     ) {
        //       formaEntrega.habil = true;
        //     } else {
        //       formaEntrega.habil = false;
        //     }
        //   } else if (formaEntrega.forma_entrega_id == "1") {
        //     formaEntrega.formaEntrega = "";
        //     if (
        //       formaEntrega.moneda_destino_id ==
        //       this.transaccion.moneda_destino_id
        //     ) {
        //       formaEntrega.habil = true;
        //     } else {
        //       formaEntrega.habil = false;
        //     }
        //   }else if (formaEntrega.forma_entrega_id == "6") {
        //     formaEntrega.formaEntrega = "";
        //     if (
        //       formaEntrega.moneda_destino_id ==
        //       this.transaccion.moneda_destino_id
        //     ) {
        //       formaEntrega.habil = true;
        //     } else {
        //       formaEntrega.habil = false;
        //     }
        //   }
        //   //this.destinatario.pais_moneda_destino_id.split("_")[1]
        //   // if (
        //   //   formaEntrega.moneda_destino_id == this.transaccion.moneda_destino_id
        //   // ) {
        //   //   formaEntrega.habil = true;
        //   // } else {
        //   //   formaEntrega.habil = false;
        //   // }
        // });
        aux.forEach((formaEntrega) => {
          if (formaEntrega.id_banco_destino != "0") {
            if (formaEntrega.forma_entrega_id == "1") {
              formaEntrega.bancoNombre = formaEntrega.bancos_nombre;
              formaEntrega.numeroCuentaMostrar = `Cta. N° ${formaEntrega.numero_cuenta}`;
            } else if (formaEntrega.forma_entrega_id == "6") {
              formaEntrega.bancoNombre = formaEntrega.bancos_nombre;
              formaEntrega.numeroCuentaMostrar = `Cta. N° ${formaEntrega.numero_cuenta_wallet}`;
            } else {
              formaEntrega.bancoNombre = "";
              formaEntrega.numero_cuenta = "";
              formaEntrega.bancoNombre = `${formaEntrega.bancos_nombre}`;
              formaEntrega.numeroCuentaMostrar = "";
            }
          } else {
            formaEntrega.bancoNombre = "aa";
            formaEntrega.numero_cuenta = "";
            let banco_caja_index = this.configuracion.pais[
              this.remitente.destinatarios[this.transaccion.id_persona]
                .pais_moneda_destino_id
            ].banco_caja.findIndex((bco_caja) => {
              bco_caja[0] == formaEntrega.banco_caja;
            });
            if (banco_caja_index > -1) {
              formaEntrega.bancoNombre =
                this.configuracion.pais[
                  this.remitente.destinatarios[
                    this.transaccion.id_persona
                  ].pais_moneda_destino_id
                ].banco_caja[banco_caja_index][0];
            }
          }

          if (formaEntrega.forma_entrega_id == "2") {
            formaEntrega.formaEntrega = `${this.t(
              "app.cotizadorDestinatario.entregaCaja"
            )} ; `;
            formaEntrega.moneda = ` ; ${this.t(
              `variables.monedas.${formaEntrega.moneda_caja}.plural`
            )}`;
            formaEntrega.habil = true;
            // if (
            //   formaEntrega.moneda_caja ==
            //   this.transaccion.moneda_destino_id
            // ) {
            //   formaEntrega.habil = true;
            // } else {
            //   formaEntrega.habil = false;
            // }
          } else if (formaEntrega.forma_entrega_id == "1") {
            formaEntrega.formaEntrega = `${this.t(
              "app.cotizadorDestinatario.deposito"
            )} ; `;
            formaEntrega.moneda = ` ; ${this.t(
              `variables.monedas.${formaEntrega.moneda_destino_id}.plural`
            )}`;
            formaEntrega.habil = true;
            // if (
            //   formaEntrega.moneda_destino_id ==
            //   this.transaccion.moneda_destino_id
            // ) {
            //   formaEntrega.habil = true;
            // } else {
            //   formaEntrega.habil = false;
            // }
          } else if (formaEntrega.forma_entrega_id == "6") {
            formaEntrega.formaEntrega = `${this.t(
              "app.cotizadorDestinatario.ewallet"
            )} ; `;
            formaEntrega.moneda = ` ; ${this.t(
              `variables.monedas.${formaEntrega.moneda_destino_id}.plural`
            )}`;
            formaEntrega.habil = true;
            // if (
            //   formaEntrega.moneda_destino_id ==
            //   this.transaccion.moneda_destino_id
            // ) {
            //   formaEntrega.habil = true;
            // } else {
            //   formaEntrega.habil = false;
            // }
          }
        });
      }

      if (aux.length > 0) {
        aux.sort((a, b) => {
          if (a.forma_entrega_id === b.forma_entrega_id) {
            return a.bancoNombre < b.bancoNombre ? -1 : 1;
          } else {
            return a.forma_entrega_id < b.forma_entrega_id ? 1 : -1;
          }
        });
      }
      // console.log(this.transaccion);
      // console.log(aux);
      return aux;
    },
    detalleFormaEntregaSeleccionado() {
      let aux = {};
      if (
        Object.keys(this.destinatario).length > 0 &&
        this.opcionesFormaEntrega.length > 0 &&
        this.formaSeleccionadaIndex != "-1" &&
        this.destinatarioSelected == this.index
      ) {
        let aux2 = this.opcionesFormaEntrega.findIndex((forma) => {
          return forma.id == this.formaSeleccionadaIndex;
        });
        if (aux2 > -1) aux = this.opcionesFormaEntrega[aux2];
      } else if (
        Object.keys(this.destinatario).length > 0 &&
        this.opcionesFormaEntrega.length > 0 &&
        this.formaSeleccionadaIndex == "-1" &&
        this.destinatarioSelected == this.index &&
        this.formaPagoSelected != "-1"
      ) {
        let aux2 = this.opcionesFormaEntrega.findIndex((forma) => {
          return forma.id == this.formaPagoSelected;
        });
        if (aux2 > -1) aux = this.opcionesFormaEntrega[aux2];
        // aux = this.opcionesFormaEntrega[this.formaPagoSelected];
      }
      // console.log(this.formaSeleccionadaIndex, this.index, this.formaPagoSelected, this.opcionesFormaEntrega);
      // console.log(aux, this.formaSeleccionadaIndex, this.destinatarioSelected, this.index);
      // console.log("------*****-----", aux);
      return aux;
    },
  },
  methods: {
    ...mapMutations([]),

    selecDestToggleFormasEntrega() {},
    changeStateChekLocal(data) {
      this.$emit("changeDestinatarioSelected", {
        estado: data,
        index: this.index,
      });
    },
    formaEntregaSelected(index) {
      // console.log(index);
      this.$emit("formaEntregaSelected", {
        destinatarioIndex: this.index,
        formaEntregaIndex: index,
      });
      this.toggleDetalleFormasEntrega = false;
      this.marcarChecked = !this.marcarChecked;
      this.formaSeleccionadaIndex = index;
    },
    call_nueva_forma_entrega() {
      this.$emit("nueva_forma_entrega", this.index);
    },
  },
  watch: {
    desplegarDetalle() {
      if (this.desplegarDetalle == this.index) {
        this.toggleDetalleFormasEntrega = true;
      } else {
        this.toggleDetalleFormasEntrega = false;
      }
    },
    toggleDetalleFormasEntrega() {
      if (this.toggleDetalleFormasEntrega) {
        this.$emit("changeDesplegarFormaEntrega", this.index);
      }
    },
    formaPagoSelected() {
      if (this.formaPagoSelected == "-1") {
        this.formaSeleccionadaIndex = "-1";
      }
    },
  },
  mounted() {
    // console.log(this.destinatario, this.transaccion);
  },
};
</script>

<style>
.cardCotiDestContainer {
  display: flex;
  padding-bottom: 1rem;
  z-index: 500;
  cursor: pointer;
}
.cardCotiDestItemIcon {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 30px;
  background: #ffffff;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);

  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #c4c4c4;
}

.cardCotiDestItemInfo {
  padding-left: 1.5rem;
  width: 80%;
  padding-top: 0.2rem;
  height: fit-content;
}
.cardCotiDestItemInfoNombre {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;
  color: #801b1c;
  width: 95%;
}
.cardCotiDestItemSelectFormaEntrega {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 400;
  font-size: 0.685rem;
  line-height: 0.8rem;
  color: #787878;
  letter-spacing: 1px;

  width: 95%;
}
.cardCotiDestItemSelectFormaEntrega .conDeco {
  text-decoration: underline;
}
.cardCotiDestItemSelectFormaEntregaSimple {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 400;
  font-size: 8px;
  line-height: 9px;
  color: #787878;
  letter-spacing: 1px;
  width: 95%;
}
.cardCotiDestItemInfoDetalle {
  position: relative;
}
.cardCotiDestItemInfoFormasEntrega {
  background: white;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  position: absolute;
  top: -0.09rem;
  left: -3rem;
  width: 16.5rem;
  padding: 1rem 0.5rem;

  font-family: "Roboto";
  font-style: italic;
  font-weight: 400;
  font-size: 0.5rem;
  line-height: 0.5625rem;
  letter-spacing: 1px;
  z-index: 550;
  overflow-y: visible;
}
.cardCotiDestItemInfoFormasEntregaRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.4rem;
  border-radius: 5px;
  background: #ececec7a;
  min-height: 2rem;
  padding: 0.3rem 0.4rem 0.3rem 0.6rem;
  /* padding: 0.2rem 0.4rem; */
}
.cardCotiDestItemInfoFormasEntregaRowText {
  /* border: 1px solid green; */
  width: 100%;
  padding-left: 0.6rem;
  color: #4c4c4c;
  font-family: "Roboto";
  font-style: italic;
  font-weight: 400;
  font-size: 10px;
  line-height: 11px;
  /* display: flex;
  align-items: flex-end; */
  letter-spacing: 1px;
}
.cardCotiDestItemInfoFormasEntregaRowTextSelected {
  /* border: 1px solid green; */
  width: 100%;
  /* padding-left: 0.6rem; */
  color: #4c4c4c;
  font-family: "Roboto";
  font-style: italic;
  font-weight: 400;
  font-size: 10px;
  line-height: 11px;
  /* display: flex;
  align-items: flex-end; */
  letter-spacing: 1px;
}
.cardCotiDestItemInfoFormasEntregaRowTextNoHabil {
  width: 100%;
  padding-left: 0.6rem;
  color: #4c4c4c;
  font-family: "Roboto";
  font-style: italic;
  font-weight: 400;
  font-size: 8px;
  line-height: 9px;
  display: flex;
  align-items: flex-end;
  letter-spacing: 1px;
}
.cardCotiDestItemInfoFormasEntregaRowText_subrayado {
  /* text-decoration: underline; */
  color: black;
}
.cardCotiDestItemInfoFormasEntregaRowText_simple {
  text-decoration: none !important;
  font-family: "Roboto";
  font-style: italic;
  font-weight: 400;
  font-size: 8px;
  line-height: 10px;
  color: #4c4c4c;
}
.cardCotiDestItemInfoFormasEntregaRowCKB {
  width: 0.7rem;
  height: 0.7rem;
  /* border: 1px solid green; */
}
.cardCotiDestItemSelect {
  width: 1.25rem;
}
.cardCotiDestNuevoFE {
  margin-top: 0.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
}
.cardCotiDestNuevoFEText {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-size: 0.625rem;
  line-height: 0.75rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #614ad3;
}
.cardCotiDestNuevoFEtBtn {
  width: 0.9rem;
}
.cardCotiDestContainer_ultimo {
  /* padding-bottom: 3rem; */
  margin-bottom: 1rem;
}

/* ////////////////-------------------------------------/////////// */
@media (min-width: 400px) {
  .cardCotiDestItemInfoFormasEntrega {
    width: 19rem;
    padding: 1rem 0.5rem;

    font-size: 0.5rem;
    line-height: 0.5625rem;
    top: 0.05rem;
  }



  .cardCotiDestItemInfoNombre {
    font-size: 0.9rem;
    line-height: 1rem;
  }
  .cardCotiDestItemSelectFormaEntrega {
    font-size: 0.785rem;
    line-height: 0.78rem;
  }
  .cardCotiDestItemInfoFormasEntregaRowText {
    font-size: 0.8rem;
    line-height: 0.8625rem;
  }
  .cardCotiDestItemInfoFormasEntregaRowTextSelected{
    font-size: 0.8rem;
    line-height: 0.8625rem;
  }
  .cardCotiDestItemInfoFormasEntregaRowText_simple {
    font-size: 0.7rem;
    line-height: 0.7625rem;
  }
}

/* ////////////////-------------------------------------/////////// */
@media (min-width: 500px) {
  .cardCotiDestItemInfoFormasEntregaRow {
    padding: 0.4rem 0.5rem 0.4rem 0.7rem;
  }
  .cardCotiDestItemInfoFormasEntregaRowCKB {
    width: 0.8rem;
    height: 100%;
  }
  .cardCotiDestItemInfoFormasEntrega {
    width: 22rem;
    padding: 1rem 0.5rem;

    font-size: 0.7rem;
    line-height: 0.7625rem;
  }
  .cardCotiDestNuevoFEText {
    font-size: 0.9rem;
    line-height: 1rem;
  }
  .cardCotiDestNuevoFEtBtn {
    width: 1.2rem;
  }
  .cardCotiDestItemInfoNombre {
    font-size: 0.9rem;
    line-height: 1rem;
  }
  .cardCotiDestItemSelectFormaEntrega {
    font-size: 0.785rem;
    line-height: 0.78rem;
  }
  .cardCotiDestItemInfoFormasEntregaRowText {
    font-size: 0.8rem;
    line-height: 0.8625rem;
  }
  .cardCotiDestItemInfoFormasEntregaRowTextSelected{
    font-size: 0.8rem;
    line-height: 0.8625rem;
  }
  .cardCotiDestItemInfoFormasEntregaRowText_simple {
    font-size: 0.7rem;
    line-height: 0.7625rem;
  }
}

/* ////////////////-------------------------------------/////////// */
@media (min-width: 600px) {
  .cardCotiDestItemInfoFormasEntregaRow {
    padding: 0.5rem 0.6rem 0.5rem 0.8rem;
  }
  .cardCotiDestItemInfoFormasEntregaRowCKB {
    width: 0.9rem;
    height: 100%;
  }
  .cardCotiDestItemInfoFormasEntrega {
    width: 25rem;
    padding: 1rem 0.5rem;

    font-size: 0.9rem;
    line-height: 0.9625rem;
  }
  .cardCotiDestNuevoFEText {
    font-size: 1rem;
    line-height: 1.1rem;
  }
  .cardCotiDestNuevoFEtBtn {
    width: 1.4rem;
  }
  .cardCotiDestItemInfoNombre {
    font-size: 1rem;
    line-height: 1.1rem;
  }
  .cardCotiDestItemSelectFormaEntrega {
    font-size: 0.885rem;
    line-height: 0.88rem;
  }
  .cardCotiDestItemInfoFormasEntregaRowText {
    font-size: 0.9rem;
    line-height: 0.9625rem;
  }
  .cardCotiDestItemInfoFormasEntregaRowTextSelected{
    font-size: 0.9rem;
    line-height: 0.9625rem;
  }
  .cardCotiDestItemInfoFormasEntregaRowText_simple {
    font-size: 0.8rem;
    line-height: 0.8625rem;
  }
}
</style>