<template>
  <div class="" @click.prevent="cambioEstadoCheck">
    <label class="containerChBoxRojo">
      <input
        type="checkbox"
        v-model="estadoCheck"
        :class="marcarSelected ? 'activo' : ''"
      />
      <span class="checkmark"></span>
    </label>
  </div>
</template>

<script>
export default {
  name: "checkBoxRojo",
  props: {
    destinatarioSelected: Number,
    index: Number,
    marcarChecked: Boolean,
    opcionesFormaEntrega: Object,
    formaPagoSelected: Number
  },
  data() {
    return {
      estadoCheck: false,
      propagarCambioEstado: true
    };
  },
  computed:{
    isArrayFormasPago(){
      let aux = false;
      if(this.opcionesFormaEntrega && this.opcionesFormaEntrega.length > 0){
        aux = true;
      }
      return aux;
    },
    marcarSelected(){
      
      let aux = false;
      if (this.destinatarioSelected == this.index){
        
        if(this.isArrayFormasPago){
          if(this.formaPagoSelected != "-1"){
            aux = true;
          }else{
            aux = false;
          }
        }else{
          // aux = true;
        }
      }else{
        aux = false;
      }
      // console.log("*******", aux, this.index, this.formaPagoSelected);
      return aux;
    },
    // pasarEstadoCheckToFalse(){
    //   let aux = false;
    //   if (this.destinatarioSelected != this.index && this.estadoCheck){
    //     aux = true;
    //   }
    //   return aux;
    // }
  },
  methods: {
    cambioEstadoCheck() {
      this.estadoCheck = !this.estadoCheck;
    },
  },
  watch: {
    estadoCheck() {
      if(this.estadoCheck){
        // if(this.propagarCambioEstado){
          this.$emit("changeStateChek", this.estadoCheck);
          this.propagarCambioEstado = true;
        // }
      }else{
        if(this.propagarCambioEstado){
          this.$emit("changeStateChek", this.estadoCheck);
          this.propagarCambioEstado = true;
        }
      }
      // if(!this.marcarSelected && this.estadoCheck){
      //   console.log("pasando por !marcarSelected para dejar estadoCheck en false (desde estadoCheck", this.index);
      //   this.emitirCambioEstadoFalse = false;
      //   this.estadoCheck = false;
      // }
      
    },
    marcarSelected(){
      if(!this.marcarSelected && this.estadoCheck){
        // console.log("pasando por !marcarSelected para dejar estadoCheck en false", this.index);
        this.propagarCambioEstado = false;
        this.estadoCheck = false;
      }
    },
    // estadoCheck(){console.log("pasando por !marcarSelected para dejar estadoCheck en false (desde estadoCheck");
      
    // },
    
    marcarChecked(){
        this.marcarSelected = true;
    },

    destinatarioSelected(){
      if (this.destinatarioSelected != this.index && this.estadoCheck){
        this.propagarCambioEstado = false;
        this.estadoCheck = false;
      }else if(this.destinatarioSelected == this.index && !this.estadoCheck){
        // this.propagarCambioEstado = false;
        this.estadoCheck = true;
      }
      
    }
  },
};
</script>

<style scoped>
.containerChBoxRojo {
  display: block;
  position: relative;
  padding-left: 25px;
  padding-top: 1px;
  cursor: pointer;
  width: 100%;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.containerChBoxRojo input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 1.25rem;
  width: 1.25rem;
  background-color: white;
  border: 0.125rem solid var(--customA-801B1C);
  border-radius: 3px;
}

.containerChBoxRojo .activo ~ .checkmark {
  background-color: var(--customA-801B1C);
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.containerChBoxRojo .activo ~ .checkmark:after {
  display: block;
}

.containerChBoxRojo .checkmark:after {
  left: 0.3125rem;
  top: 0.2rem;
  width: 5px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>